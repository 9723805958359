import React from 'react'

function Photography() {
  return (
    <div className='w-full bg-white px-4 h-screen flex items-center'>
      <div className='max-w-[950px] mx-auto w-[950px]'>
        <div className='flex flex-col justify-center w-full'>
            <p className='text-gray-700 font-semibold text-xs text-end font-[rubik]'>Capturing the Essence of Cars.</p>
            <h1 className='md:text-4xl sm:text-3xl text-3xl font-normal text-[#006454] text-end font-[OldStandardTT]'>PHOTOGRAPHY</h1>
            <p className='text-gray-600 mt-16 text-end font-[apple]'>Step into a world where every click unveils the essence of automotive beauty.</p> 
            <p className='text-gray-600 mt-7 text-end font-[apple]'>Explore the artistry of photography redefined, where each image narrates a tale of elegance and precision.</p> 
            <p className='text-gray-600 mt-7 text-end font-[apple]'>Experience the epitome of sophistication as I unveil the timeless allure of automotive excellence through my lens.</p> 
            

            <div className='w-full h-[1px] rounded-full bg-[#006454] mt-20'></div>
        </div>
        
      </div>
    </div>
  )
}

export default Photography
