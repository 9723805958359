import React from 'react'

function BookingSection() {
  return (
    <div className='w-full bg-white px-4 h-screen flex items-center'>
      <div className='max-w-[900px] mx-auto '>
        <p className='font-[OldStandardTT] lg:text-4xl text-3xl text-[#006454] font-normal text-center'>LET ME PROVE MY KNOWLEDGE</p>
        <p className='font-[apple] text-center lg:mt-10 mt-24 text-gray-700'>Secure your slot effortlessly with an intuitive booking system.</p>
        <p className='font-[apple] text-center lg:mt-3 mt-10 text-gray-700'>Explore our user-friendly platform designed for scheduling your automotive photography and videography sessions.</p>
        <p className='font-[apple] text-center lg:mt-3 mt-10 text-gray-700'>Elevate your storytelling journey with seamless scheduling tailored to your needs.</p>
        <div className='h-[1px] bg-black w-full rounded-full mt-10'></div>
        <a href="https://azureh.setmore.com/">
          <div className='h-[50px] w-[240px] bg-[#006454] mx-auto mt-12 rounded-xl text-white flex justify-center items-center transform hover:scale-[1.06] duration-500'>
            <p className='font-[apple-semibold]'>Book appointment</p>
          </div>
        </a>
        </div>
    </div>
  )
}
export default BookingSection
