import React from 'react'
import {
    FaInstagram,
    FaTiktok,
} from "react-icons/fa"
import logo from '../assets/feketelogo.png'

function Portfolio() {
  return (
    <div className='pb-[40px] border-t bg-[#dfdfdf]'>
        <p className='text-center text-gray-700 mt-11 font-light font-[roboto]'>© 2024 AZUREH. All rights reserved.</p>
        <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-black pt-[50px]'>
            <div className='flex flex-col items-center justify-center'>
                <img src={ logo } alt='AZUREH' className='h-[25px]' />
            </div>
            <p className='py-4 text-center text-gray-700 font-normal font-[roboto]'>Explore my stunning automotive photography portfolio on TikTok and Instagram. My lens captures the essence of cars like never before.</p>

            <div>
                <div className='flex justify-center gap-9 items-center md:w-full my-6 lg:mt-[20px]'>
                    <a href='https://www.instagram.com/azureh_official/'><FaInstagram size={30} className='cursor-pointer' /></a>
                    <a href='https://www.tiktok.com/@azureh_official'><FaTiktok size={25} className='cursor-pointer'/></a>
                </div>

                <p className='text-center font-[roboto] font-normal'>info@azureh.com</p>
            </div>
            
            </div>
            {/* <div className='w-full h-[1px] rounded-full bg-[#006454] mt-5'></div> */}
    </div>
  )
}

export default Portfolio
