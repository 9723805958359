import React from 'react'

function DeliverySection() {
  return (
    <div className='w-full bg-white px-4 h-full flex items-center'>
      <div className='max-w-[950px] w-full mx-auto flex lg:flex-row flex-col items-center justify-between'>
        <div>
            <p className='font-[OldStandardTT] lg:text-4xl text-3xl text-[#006454] font-normal text-start'>DELIVERY</p>
            <p className='font-[apple] text-start mt-10 text-gray-700 lg:w-[500px]'>Effortlessly transfer your crafted visuals with my streamlined delivery service. </p>
            <p className='font-[apple] text-start mt-7 text-gray-700 lg:w-[500px]'>Seamlessly transmit your completed images and videos through my dedicated web platform. </p>
            <p className='font-[apple] text-start mt-7 text-gray-700 lg:w-[500px]'>Experience efficiency and reliability as I ensure swift and hassle-free delivery, ready to elevate your automotive storytelling experience.</p>
    
        </div>
        
        <a href="https://cloud.azureh.com/" className='lg:w-[240px] w-full'>
          <div className='h-[240px] lg:w-[240px] w-full border-[2px] border-solid border-[#006454] mx-auto mt-12 rounded-xl text-white flex justify-center items-center transform hover:scale-[1.06] duration-500'>
            <p className='font-[apple] text-black font-normal'>Go To CLOUD</p>
            <svg width="26" height="26" fill="none" stroke="#006454" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className='ml-5' xmlns="http://www.w3.org/2000/svg">
                <path d="m12.563 5.25 6.75 6.75-6.75 6.75"></path>
                <path d="M18.375 12H4.687"></path>
            </svg>
          </div>
        </a>
        </div>
    </div>
  )
}

export default DeliverySection
