import React from 'react'

import oszlopkep from "../assets/oszlopkepek.png"
import phonepng from "../assets/phonepng.png"

import threephones from "../assets/threephones.png"
import Footer from './Footer'

function PortfolioMainn() {
  return (
    <div className='h-full w-full bg-white'>
      <div className='h-full w-full max-w-[1000px] mx-auto py-20 mt-32 bg-white'>
        <p className='text-4xl text-[#006454] w-full text-center font-[OldStandardTT] font-normal'>PORTFOLIO</p>
        <p className='text-center font-[apple] text-sm'>of some cars, I've seen.</p>
        
        <img src={ threephones } alt="/" className='lg:mt-10 mt-32'/>

        

        <div className='lg:h-[3700px] xl:h-[3800px] 2xl:h-[4500px] h-[3510px]  w-full  mx-auto relative bg-white z-10 border-black border-[0px] border-solid lg:mt-10 mt-0'>
            <img src= { oszlopkep } alt='/' className='absolute left-1/2 transform -translate-x-1/2 lg:top-[172px] xl:top-[170px] top-[145px] mx-auto w-[250px] lg:w-[260px] xl:w-[270px] 2xl:w-[320px] z-0'/>
            <img src= { phonepng } alt='/' className='sticky top-[0px] flex justify-center items-center z-20 h-screen mx-auto'/>
        </div>
        

        <div className='h-full w-full bg-white px-6'>
          <p className='w-full text-center text-gray-700 font-[apple]'>I cordially invite you to explore my complete portfolio on Instagram, <br></br>where every image tells a story of meticulous craftsmanship and unparalleled elegance.</p>
          <p className='w-full text-center text-gray-700 font-[apple] mt-10'>Immerse yourself in the captivating narrative of my brand as I showcase my finest works, <br></br>meticulously curated to inspire and captivate.</p>
            <a href='https://www.instagram.com/azureh_official/' className='h-[40px] w-[220px] mt-20 rounded-md border-black border-[1px] border-solid mx-auto flex justify-center items-center shadow-2xl cursor-pointer'>
              <p className='font-[apple] text-xl text-center text-[#006454] font-normal ml-[-10px]'>@azureh_official</p>
            </a>
        </div>

        
       
        <div className='h-[70px]'></div>
        
      </div>
      <Footer />
    </div>
  )
}

export default PortfolioMainn
