import React, { useRef, useEffect } from 'react'
import Typed from 'react-typed'
import bmwvideo from '../assets/bmwm4comp_reduced2.mp4'
import logo from '../assets/feherlogo.png'
import uparrow from '../assets/arrow-up-circle.svg' 
import downarrow from '../assets/arrow-down-circle.svg'

export function AutoPlaySilentVideo() {
  const videoRef = useRef(undefined);
  useEffect(() => {
      videoRef.current.defaultMuted = true;
  })
  return (
      <video
          className={'w-full h-screen mx-auto object-cover'}
          ref={videoRef}
          loop
          autoPlay
          muted
          playsInline>
          <source src={ bmwvideo } type="video/mp4"/>
      </video>
  );
}

function Hero(props, ref) {
  return (
    <div className='text-black w-full h-screen flex flex-col justify-center items-center bg-black' ref={ ref }>
      {/* <video src={ bmwvideo } autoPlay loop muted className='w-full h-screen mx-auto object-cover'/> */}
      { AutoPlaySilentVideo() }
      <div className=' absolute max-w-[800px] mx-auto text-center flex flex-col justify-center max-h-20'>
        <div className='flex flex-col justify-center items-center'>
          {/* <p className='text-[#a1a1a1] font-bold p-2 md:text-s sm:text-s text-xs mb-[-10px]'>Future of Possible.</p> */}
          {/* <h1 className='md:text-7xl sm:text6xl text-4xl font-bold md:py-6'>Grow with data.</h1> */}
          <img className='md:scale-[0.3] scale-[0.4]' alt='AZUREH' src={ logo }></img>

          <img src={ uparrow } alt='UP' className='scale-[0.4] mb-[-10px]'/>
          <p className='text-gray-500 text-xs mb-[-30px] font-[rubik]'>Scroll Up</p>
        </div>
        

        
        <div className='flex justify-center items-center mt-[20px]'>
            <p className='md:text-4xl sm:text-3xl text-lg font-light py-4 text-gray-300 font-[apple]'>I'm sure, you need a </p>
            <Typed strings={['PHOTO','VIDEO']} typeSpeed={120} backSpeed={140} loop className='md:text-4xl sm:text-3xl text-lg font-medium font-[apple-semibold] md:pl-4 lg:mb-0 lg:pl-2 lg:pr-2 pl-1 pr-1 text-[#006454]'/>
            <p className='md:text-4xl sm:text-3xl text-lg font-light py-4 text-gray-300 font-[apple]'> of your car.</p>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <p className='md:text-2xl text-md font-light px-4 text-gray-300 font-[apple]'>Check out my <span className='text-[#006454] font-medium'> PORTFOLIO.</span></p>
          <p className='text-gray-500 text-xs mt-[10px] font-[rubik]'>Scroll Down</p>
          <img src={ downarrow } alt='DOWN' className='scale-[0.4] mt-[-6px]'/>
        </div>
        

        
      </div>
    </div>
  )
}

export default React.forwardRef(Hero);
