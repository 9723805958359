import React, { useEffect, useRef } from 'react';
import Hero from './Hero';
import Videography from './Videography';
import Photography from './Photography';
import Portfolio from './Portfolio';
import PortfolioMainn from './PortfolioMainn';
import BookingSection from './BookingSection';
import DeliverySection from './DeliverySection';

function MainWeb() {

  const heroRef = useRef();

  useEffect(() => {
    window.scrollTo(0, heroRef.current.offsetTop);
  }, []);

  return (
    <div>
      <Portfolio />
      <Videography />
      <Photography />
      <DeliverySection />
      <BookingSection />
      <Hero ref={heroRef} />
      <PortfolioMainn />
    </div>
  )

}

export default MainWeb
