import React from 'react'

function Videography() {
  return (
    <div>
      <div className='w-full bg-white px-4 lg:h-full mt-44  flex items-center lg:pb-0 pb-24 lg:pt-0 pt-14'>
        <div className='max-w-[950px] mx-auto'>
          <div className='flex flex-col justify-center w-full'>
              <p className='text-gray-700 font-semibold text-xs font-[rubik]'>Driving Dreams, Framing Passions.</p>
              <h1 className='md:text-4xl sm:text-3xl text-3xl font-normal text-[#006454] font-[OldStandardTT]'>VIDEOGRAPHY</h1>
              <p className='text-gray-600 mt-16 font-[apple]'>Embark on a journey through the lens of automotive excellence. </p>
              <p className='text-gray-600 mt-7 font-[apple]'>Witness the symphony of speed and elegance captured in every frame. </p>
              <p className='text-gray-600 mt-7 font-[apple]'>Experience the pinnacle of premium automotive videography, where each shot exudes unparalleled sophistication and timeless allure.</p>
              <p className='text-gray-600 mt-7 font-[apple]'>Welcome to a realm where craftsmanship meets artistry.</p>
              
              <div className='w-full h-[1px] rounded-full bg-[#006454] mt-20'></div>
          </div>
        </div>
        
      </div>
    </div>
    
  )
}

export default Videography
